import { gql } from "@apollo/client";
import { ButtonBase, IconButton, Paper } from "@mui/material";
import schickBanner350x160Jpg from "assets/schick/schick_banner_350x160.jpg";
import schickBanner740x120Jpg from "assets/schick/schick_banner_740x120.jpg";
import schickBanner1280x120Jpg from "assets/schick/schick_banner_1280x120.jpg";
import { SCHICK_BANNER_ENABLED } from "helpers/constants";
import useData from "helpers/useData";
import { CloseCircle } from "mdi-material-ui";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "react-use-size";

export default function SchickBanner({ ...others }) {
  const windowSize = useWindowSize();
  const imgSrc =
    windowSize.width < 740 + 30
      ? schickBanner350x160Jpg
      : windowSize.width < 1280 + 30
        ? schickBanner740x120Jpg
        : schickBanner1280x120Jpg;
  const [open, openSet] = useState(false);

  const [data] = useData(
    gql`
      query SchickBanner {
        currentUser {
          id
          schickBannerEnabled
        }
      }
    `,
    {},
    { skip: !SCHICK_BANNER_ENABLED },
  );

  useEffect(() => {
    if (data?.currentUser?.schickBannerEnabled) {
      openSet(true);
    }
  }, [data?.currentUser?.id]);

  return (
    open && (
      <Paper
        component={ButtonBase}
        href="https://www.everydayballers.basketball/"
        target="_blank"
        elevation={10}
        {...others}
        style={{
          position: "relative",
          ...others.style,
        }}
      >
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            openSet(false);
          }}
          size="small"
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            right: 0,
          }}
        >
          <CloseCircle />
        </IconButton>
        <img src={imgSrc} alt="Schick Banner" style={{ objectFit: "contain" }} />
      </Paper>
    )
  );
}
