import { gql } from "@apollo/client";
import { EVENT_PARAMS_KEYS } from "helpers/EVENT_TEMPLATES";
import { gaEvent } from "helpers/gtag";
import useAction from "helpers/useAction";
import useActionFragment from "helpers/useActionFragment";
import useConfirmDialog from "helpers/useConfirmDialog";
import useShowMessage from "helpers/useShowMessage";
import { pick } from "lodash-es";
import { DialogActionButton } from "molecules/Dialog";
import FormDialog from "molecules/FormDialog";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext, useLayoutEffect, useState } from "react";

import HighlightEditor, { HighlightEditorEventFragment, HighlightEditorFragment } from "./HighlightEditor";

export const EditHighlightDialogFragment = gql`
  fragment EditHighlightDialogFragment on Game {
    id
    events {
      id
      ...HighlightEditorEventFragment
      eventView {
        id
        wrapper
      }
    }
    ...HighlightEditorFragment
  }
  ${HighlightEditorFragment}
  ${HighlightEditorEventFragment}
`;

export default function EditHighlightDialog({ game, open, eventId, onClose }) {
  const { currentPeriodNumber } = useContext(videoPageContext);

  const onsenUpsertEvent = useAction(gql`
    mutation EditHighlightDialog($input: OnsenUpsertEventInput!) {
      onsenUpsertEvent(input: $input) {
        event {
          id
          ...HighlightEditorEventFragment
        }
      }
    }
    ${HighlightEditorEventFragment}
  `);
  const onsenDeleteEvent = useActionFragment("onsenDeleteEvent", "game { id events { id } }");

  const [event, eventSet] = useState({});

  useLayoutEffect(() => {
    eventSet(game?.events.find((event) => event.id === eventId));
  }, [!!game, eventId]);
  const [confirmDialog, confirmDialogOpen] = useConfirmDialog();
  const showMessage = useShowMessage();

  return (
    <FormDialog
      open={open}
      title="Edit Highlight"
      onClose={onClose}
      onSubmit={async () => {
        await onsenUpsertEvent({
          input: {
            gameId: game.id,
            eventId: event.id,
            period: currentPeriodNumber,
            personId: event.person?.id,
            teamId: event.team?.id,
            occurredAtSeconds: event.occurredAtSeconds,
            startAtSeconds: event.startAtSeconds,
            finishAtSeconds: event.finishAtSeconds,
            ...pick(event.eventView, EVENT_PARAMS_KEYS),
          },
        });
        gaEvent("event_edit_save", {
          event_type: event.eventView.wrapper,
        });
      }}
      inverse
      fullWidth
      fullHeight
      actions={
        <>
          <DialogActionButton
            label="Delete this highlight"
            flat
            onClick={async () => {
              await confirmDialogOpen();
              await onsenDeleteEvent({ input: { eventId: event.id } });
              showMessage("Highlight deleted");
              onClose();
            }}
          />
        </>
      }
    >
      {confirmDialog}
      <HighlightEditor open={open} event={event} eventSet={eventSet} game={game} />
    </FormDialog>
  );
}
