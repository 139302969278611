export const {
  //
  REVISION,
  ONSEN_ENV,
  ONSEN_APP,
  ONSEN_URL,
  GLORYLEAGUE_URL,
  GLORYLEAGUE_API_URL,
  ONSEN_PUBLIC_LEAGUES_HOST_SUFFIX,
  GA_MEASURE_ID,
} =
  // eslint-disable-next-line no-undef
  ONSEN_ENVIRONMENTS;
