import { gql } from "@apollo/client";
import { TextField } from "@mui/material";
import apolloClient from "helpers/apolloClient";
import { ONSEN_AUTH_TOKEN_KEY } from "helpers/constants";
import { GLORYLEAGUE_URL, ONSEN_APP } from "helpers/environment";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import useEnforceAuth from "helpers/useEnforceAuth";
import useShowMessage from "helpers/useShowMessage";
import React, { useState } from "react";
import FormPage, { FormPageSubmitButton } from "shared/FormPage";
import PageTitle from "shared/PageTitle";

export default function LoginPage() {
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  useEnforceAuth({ enforceUnauthenticated: true });
  const login = useActionFragment("login", "authToken");
  const showMessage = useShowMessage();

  return (
    <>
      <PageTitle title="Login" />
      <FormPage
        title={
          <>
            Login to <b>Glory League</b>
          </>
        }
        onSubmit={async () => {
          const result = await login({
            input: {
              email,
              password,
            },
          });
          const authToken = result.login.authToken;
          if (!authToken) {
            await showMessage("Invalid email or password");
            return;
          }
          window.localStorage.setItem(ONSEN_AUTH_TOKEN_KEY, authToken);
          await apolloClient.query({
            fetchPolicy: "network-only",
            query: gql`
              query LoginPage {
                currentUser {
                  id
                }
              }
            `,
          });
          gaEvent("login", { method: "form" });
        }}
      >
        <TextField
          autoFocus={!ONSEN_APP}
          label="Email Address"
          autoComplete="email"
          value={email}
          onChange={(event) => emailSet(event.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(event) => passwordSet(event.target.value)}
          required
        />
        <FormPageSubmitButton label="Login" />
        <div>
          Don&apos;t have a new password or forgot your password? <a href="/reset-password">reset password</a>
        </div>
        {!ONSEN_APP && (
          <div>
            Are you an administrator? <a href={`${GLORYLEAGUE_URL}/readmin/`}>Login here</a>
          </div>
        )}
      </FormPage>
    </>
  );
}
