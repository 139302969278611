import { ERROR_MESSAGE_EMAILS_DO_NOT_MATCH } from "pages/person/PersonAccount";
import Rollbar from "rollbar";
import { ERROR_MESSAGE_PAGE_NOT_FOUND } from "shared/NotFoundPage";

import { ERROR_MESSAGE_CANCELLED } from "./AbortError";
import { ONSEN_ENV, REVISION } from "./environment";

const rollbar = new Rollbar({
  enabled: ONSEN_ENV !== "test",
  environment: ONSEN_ENV,
  accessToken: "409ad5f91fc74408af29e8b015d3be07",
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: [
    //
    ERROR_MESSAGE_CANCELLED,
    ERROR_MESSAGE_EMAILS_DO_NOT_MATCH,
    ERROR_MESSAGE_PAGE_NOT_FOUND,
    "The operation was aborted.", // aborted XHRs
    "The play() request was interrupted", // https://developer.chrome.com/blog/play-request-was-interrupted
    "Abort due to cancellation of share.",
  ],
  payload: {
    client: {
      javascript: { code_version: REVISION },
    },
  },
});

export default rollbar;
