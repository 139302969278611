import { gql } from "@apollo/client";
import { alpha, ButtonBase } from "@mui/material";
import HoverModal from "atoms/HoverModal";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import { CloseCircle } from "mdi-material-ui";
import Dialog, { DialogActionButton } from "molecules/Dialog";
import GamePlayerAvatar, { GamePlayerAvatarFragment } from "molecules/GamePlayerAvatar";
import { videoPageContext } from "pages/VideoPage";
import React, { Fragment, useContext, useState } from "react";

export const EventPromptFragment = gql`
  fragment EventPromptFragment on Game {
    events {
      id
      occurredAtSeconds
      eventView {
        id
        wrapper
      }
      team {
        id
      }
      person {
        id
      }
    }
    participations {
      id
      isHomeTeam
      team {
        id
      }
      gamePlayers {
        id
        person {
          id
          shortNameWithAnonymisation
        }
        ...GamePlayerAvatarFragment
      }
    }
  }
  ${GamePlayerAvatarFragment}
`;

export function EventPrompt({
  game,
  enabled = false,
  title,
  color,
  noEventLabel,
  disablePropmtLabel,
  enableSameTeamGamePlayers = false,
  enableOtherTeamGamePlayers = false,
  onSelectGamePlayer,
  onSelectNoEvent,
  ...others
}) {
  const [open, openSet] = useState(false);
  const { activeEventId, dismissedPromptStrings, dismissedPromptStringsSet } = useContext(videoPageContext);
  const promptString = `${title}-${activeEventId}`;

  if (dismissedPromptStrings.includes(promptString) || !enabled) return null;

  const activeEvent = game?.events.find((event) => event.id === activeEventId);

  return (
    <>
      <div
        {...others}
        style={{
          position: "relative",
          top: "-100%",
          ...others.style,
        }}
      >
        <HoverModal
          element={
            <Stack
              element={<ButtonBase focusRipple onClick={() => openSet(true)} />}
              dense
              padding
              style={{
                borderRadius: SPACING_DENSE,
                backgroundColor: alpha(color, 0.8),
                color: COLOR_BLACK,
                fontSize: 12,
                position: "relative",
              }}
            >
              {title}
            </Stack>
          }
        />
        <WithTooltip
          tooltip="Dismiss"
          element={
            <ButtonBase
              onClick={() => {
                dismissedPromptStringsSet([...dismissedPromptStrings, promptString]);
              }}
              style={{
                color: COLOR_BLACK,
                backgroundColor: COLOR_WHITE,
                borderRadius: "50%",
                position: "absolute",
                zIndex: 1,
                fontSize: SPACING,
                top: `-${SPACING_DENSE}`,
                right: `-${SPACING_DENSE}`,
              }}
            >
              <CloseCircle fontSize="inherit" />
            </ButtonBase>
          }
        />
      </div>
      <Dialog
        open={open}
        onClose={() => openSet(false)}
        actions={
          <>
            <DialogActionButton
              onClick={async () => {
                onSelectNoEvent?.();
                openSet(false);
              }}
              label={noEventLabel}
            />
          </>
        }
      >
        <Stack dense>
          {game?.participations
            .filter(
              (p) =>
                (enableSameTeamGamePlayers && p.team?.id === activeEvent?.team?.id) ||
                (enableOtherTeamGamePlayers && p.team?.id !== activeEvent?.team?.id),
            )
            .map((participation) => (
              <Fragment key={participation.id}>
                <div
                  style={{
                    fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                    textTransform: "uppercase",
                    fontSize: 24,
                  }}
                >
                  {participation.team.name}
                </div>
                <Stack dense horizontal alignItemsCenter>
                  {participation.gamePlayers
                    .filter((gp) => gp.person.id !== activeEvent?.person?.id)
                    .map((gamePlayer) => (
                      <WithTooltip
                        key={gamePlayer.id}
                        element={
                          <ButtonBase
                            onClick={() => {
                              onSelectGamePlayer({ personId: gamePlayer.person.id, teamId: participation.team.id });
                              openSet(false);
                            }}
                            focusRipple
                          />
                        }
                        tooltip={`${gamePlayer.person.shortNameWithAnonymisation} #${gamePlayer.playerNumber}`}
                      >
                        <GamePlayerAvatar key={gamePlayer.id} gamePlayer={gamePlayer} />
                      </WithTooltip>
                    ))}
                </Stack>
              </Fragment>
            ))}
        </Stack>
      </Dialog>
    </>
  );
}
