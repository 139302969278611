import { gql } from "@apollo/client";
import { flatMap, fromPairs, isEqual, pick } from "lodash-es";

export const EVENT_PARAMS_KEYS = ["wrapper", "points", "made", "reboundType"];
export const EventParamsFragment = gql`
  fragment EventParamsFragment on EventView {
    wrapper
    points
    made
    reboundType
  }
`;
export const EVENT_DEFAULT_PARAMS = fromPairs(EVENT_PARAMS_KEYS.map((key) => [key, null]));

const EVENT_TEMPLATES = [
  // TODO: merge minitball into basketball
  ...flatMap(["Basketball", "Miniball"], (sport) => [
    {
      sport,
      shortName: "1PT",
      title: "1 Point",
      params: {
        wrapper: `${sport}Point`,
        made: true,
        points: 1,
      },
      assistPromptEnabled: true,
      assistPromptWrapper: `${sport}Assist`,
    },
    {
      sport,
      shortName: "2PT",
      title: "2 Point",
      params: {
        wrapper: `${sport}Point`,
        made: true,
        points: 2,
      },
      assistPromptEnabled: true,
      assistPromptWrapper: `${sport}Assist`,
    },
    {
      sport,
      shortName: "3PT",
      title: "3 Point",
      params: {
        wrapper: `${sport}Point`,
        made: true,
        points: 3,
      },
      assistPromptEnabled: true,
      assistPromptWrapper: `${sport}Assist`,
    },
    {
      sport,
      shortName: "AST",
      title: "Assist",
      params: {
        wrapper: `${sport}Assist`,
      },
    },
    {
      sport,
      shortName: "BLK",
      title: "Block",
      params: {
        wrapper: `${sport}Block`,
      },
      reboundPromptEnabled: true,
      reboundPromptWrapper: `${sport}Rebound`,
    },
    {
      sport,
      shortName: "STL",
      title: "Steal",
      params: {
        wrapper: `${sport}Steal`,
      },
    },
    {
      sport,
      shortName: "OR",
      title: "Offensive Rebound",
      params: {
        wrapper: `${sport}Rebound`,
        reboundType: "offensive",
      },
    },
    {
      sport,
      shortName: "DR",
      title: "Defensive Rebound",
      params: {
        wrapper: `${sport}Rebound`,
        reboundType: "defensive",
      },
    },
    {
      sport,
      shortName: "TO",
      title: "Turnover",
      params: {
        wrapper: `${sport}Turnover`,
      },
    },
    {
      sport,
      shortName: "1PM",
      title: "1 Point Miss",
      params: {
        wrapper: `${sport}Point`,
        made: false,
        points: 1,
      },
      reboundPromptEnabled: true,
      reboundPromptWrapper: `${sport}Rebound`,
    },
    {
      sport,
      shortName: "2PM",
      title: "2 Point Miss",
      params: {
        wrapper: `${sport}Point`,
        made: false,
        points: 2,
      },
      reboundPromptEnabled: true,
      reboundPromptWrapper: `${sport}Rebound`,
    },
    {
      sport,
      shortName: "3PM",
      title: "3 Point Miss",
      params: {
        wrapper: `${sport}Point`,
        made: false,
        points: 3,
      },
      reboundPromptEnabled: true,
      reboundPromptWrapper: `${sport}Rebound`,
    },
  ]),
  {
    sport: "Futsal",
    shortName: "G",
    title: "Goal",
    params: {
      wrapper: "FutsalPoint",
      made: true,
      points: 1,
    },
    assistPromptEnabled: true,
    assistPromptWrapper: "FutsalAssist",
  },
  {
    sport: "Futsal",
    shortName: "M",
    title: "Miss",
    params: {
      wrapper: "FutsalPoint",
      made: false,
      points: 1,
    },
  },
  {
    sport: "Futsal",
    shortName: "SAV",
    title: "Save",
    params: {
      wrapper: "FutsalSave",
    },
  },
  {
    sport: "Futsal",
    shortName: "AST",
    title: "Assist",
    params: {
      wrapper: "FutsalAssist",
    },
  },
];

export function getEventTemplate(sport, event) {
  return EVENT_TEMPLATES.find(
    (t) =>
      t.sport === sport &&
      isEqual(pick(event.eventView, EVENT_PARAMS_KEYS), {
        ...EVENT_DEFAULT_PARAMS,
        ...t.params,
      }),
  );
}

export default EVENT_TEMPLATES;
