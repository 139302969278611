import { gql } from "@apollo/client";
import { ONSEN_URL } from "helpers/environment";
import { gaEvent } from "helpers/gtag";
import isUniqueVideoPlay from "helpers/isUniqueVideoPlay";
import useActionFragment from "helpers/useActionFragment";
import useData from "helpers/useData";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import React from "react";

import ReelVideoPlayer from "./ReelVideoPlayer";

export const HighlightPlayerFragment = gql`
  fragment HighlightPlayerFragment on EventView {
    title
    humanName
    thumbnailUrl
    videoUrl
    videoPending
    viewCount
    shareable
    ...EventAvatarFragment
  }
  ${EventAvatarFragment}
`;

export default function HighlightPlayer({ eventView, eventViewId }) {
  useData(
    gql`
      query EventPage($eventViewId: ID!) {
        eventView(id: $eventViewId) {
          id
          videoPending
        }
      }
    `,
    { eventViewId },
    { pollInterval: 5000, skip: !eventView || !eventView.videoPending },
  );

  const eventViewIncreaseViewCount = useActionFragment("eventViewIncreaseViewCount", "eventView { id viewCount }");
  const eventViewIncreaseShareCount = useActionFragment("eventViewIncreaseShareCount");

  return (
    <>
      <ReelVideoPlayer
        avatar={<EventAvatar eventView={eventView} />}
        title={eventView?.title}
        subtitle={eventView?.humanName}
        poster={eventView?.thumbnailUrl}
        videoUrl={eventView?.videoUrl}
        shareUrl={`${ONSEN_URL}/event/${eventViewId}`}
        sharingDisabled={eventView?.shareable === false}
        videoPending={eventView?.videoPending}
        vidoeUnavailable={!!eventView && !eventView.videoUrl && !eventView.videoPending}
        viewCount={eventView?.viewCount}
        onShared={({ share_destination }) => {
          gaEvent("event_share", {
            share_destination,
          });
          eventViewIncreaseShareCount({ input: { eventViewId } });
        }}
        onVideoPlay={() => {
          const isUnique = isUniqueVideoPlay("event_" + eventViewId);
          gaEvent("event_play", {
            is_unique: isUnique,
          });
          eventViewIncreaseViewCount({ input: { eventViewId, isUnique } });
        }}
        onDownload={() => {
          gaEvent("download", { type: "event" });
        }}
      />
    </>
  );
}
