import { gql } from "@apollo/client";
import { darken, lighten } from "@mui/material";
import { ButtonBase } from "@mui/material";
import { COLOR_WHITE, TEAM_COLORS } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import useData from "helpers/useData";
import React, { useState } from "react";

export default function GamesSplash({ title, subtitle, teamId }) {
  const [data] = useData(
    gql`
      query GamesSplash($teamId: ID) {
        team(id: $teamId) {
          id
          colour
          logoUrl
        }
        currentUser {
          id
          gamesSeasons(limit: 1) {
            id
          }
        }
      }
    `,
    {
      teamId,
    },
  );

  const teamColor = TEAM_COLORS[data?.team?.colour || "red"];
  const [tabHoveringTitle, tabHoveringTitleSet] = useState(null);
  const firstSeasonId = data?.currentUser?.gamesSeasons[0]?.id;

  return (
    <div
      style={{
        minHeight: 150,
        color: COLOR_WHITE,
        backgroundColor: teamColor,
        background: `linear-gradient(to bottom, ${lighten(teamColor, 0.15)} 0%, ${darken(teamColor, 0.2)} 100%)`,
        position: "relative",
      }}
    >
      {data?.team?.logoUrl && (
        <div
          style={{
            opacity: 0.4,
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundImage: `url(${data.team.logoUrl})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center right",
          }}
        />
      )}
      <div
        style={{
          fontFamily: FONT_FAMILY_ROBOTO,
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "flex-end",
          padding: 20,
          gap: 10,
          fontSize: 20,
          position: "relative",
        }}
      >
        <div style={{ fontWeight: 600, fontSize: "1.4em" }}>{title}</div>
        <div>|</div>
        <div>{subtitle}</div>
        <div style={{ flex: "1 1 auto" }} />
        <div style={{ display: "flex", gap: 20 }}>
          {[
            ["/games", "Games"],
            [`/leaderboards?seasonId=${firstSeasonId}`, "Leaderboards", { disabled: !firstSeasonId }],
          ].map(([href, text, others]) => (
            <ButtonBase
              {...others}
              key={text}
              href={href}
              onMouseEnter={() => tabHoveringTitleSet(text)}
              onMouseLeave={() => tabHoveringTitleSet(null)}
              style={{
                color: COLOR_WHITE,
                textTransform: "uppercase",
                fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                fontSize: 24,
                fontWeight: 400,
                opacity: 0.7,
                paddingBottom: 20,
                marginBottom: -20,
                ...((tabHoveringTitle === text || text === title) && {
                  opacity: 1,
                  borderBottom: `3px solid ${lighten(teamColor, 0.2)}`,
                }),
              }}
            >
              {text}
            </ButtonBase>
          ))}
        </div>
      </div>
    </div>
  );
}
