import HLSVideo from "atoms/HLSVideo";
import { modalContext } from "helpers/contexts";
import { gaEvent } from "helpers/gtag";
import mergeRefs from "helpers/mergeRefs";
import {
  SHORTCUT_KEY_CODE_VIDEO_PLAY_PAUSE,
  SHORTCUT_KEY_CODE_VIDEO_SKIP_BACKWARD,
  SHORTCUT_KEY_CODE_VIDEO_SKIP_FORWARD,
  SHORTCUT_KEY_CODE_VIDEO_SLOW_DOWN,
  SHORTCUT_KEY_CODE_VIDEO_SPEED_UP,
} from "helpers/SHORTCUTS";
import useDOMEvent from "helpers/useDOMEvent";
import { useVideoVolumn } from "helpers/useVideoVolumn";
import React, { useContext, useEffect, useRef, useState } from "react";

// Extension of HLSVideo component, with:
//
// - common keyboard controls
// - dragging to seek
// - applying volume
// - only playing when current modal is active
//
export default function VideoPlayer({
  slowDownPlaybackRate = 0.3,
  speedUpPlaybackRate = 3,
  skippingSeconds = 1,
  ...others
}) {
  const videoRef = useRef();
  const [videoVolume] = useVideoVolumn();

  useDOMEvent("keydown", (event) => {
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_SLOW_DOWN) {
      videoRef.current.playbackRate = slowDownPlaybackRate;
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    if (event.code === SHORTCUT_KEY_CODE_VIDEO_SPEED_UP) {
      videoRef.current.playbackRate = speedUpPlaybackRate;
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    if (event.code === SHORTCUT_KEY_CODE_VIDEO_SKIP_FORWARD) {
      videoRef.current.currentTime += skippingSeconds;
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    if (event.code === SHORTCUT_KEY_CODE_VIDEO_SKIP_BACKWARD) {
      videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - skippingSeconds);
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });

  // revert speed
  useDOMEvent("keyup", (event) => {
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_SLOW_DOWN || event.code === SHORTCUT_KEY_CODE_VIDEO_SPEED_UP) {
      videoRef.current.playbackRate = videoRef.current.defaultPlaybackRate;
    }
  });

  useDOMEvent("keypress", (event) => {
    if (document.querySelector(":focus")) return;
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PLAY_PAUSE) {
      if (videoRef.current.paused) videoRef.current.play();
      else videoRef.current.pause();
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  });

  // dragging
  const playingWhenDraggingRef = useRef(null);
  const [draggingState, draggingStateSet] = useState(null);

  useDOMEvent("touchmove", (event) => {
    if (!draggingState) return;
    const rect = videoRef.current.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left - draggingState.startX;
    const startProgress = draggingState.startCurrentTime / videoRef.current.duration;
    const deltaProgress = x / 800;
    let newProgress = startProgress + deltaProgress;
    newProgress = Math.max(0, Math.min(1, newProgress));
    const newCurrentTime = newProgress * videoRef.current.duration;
    videoRef.current.currentTime = newCurrentTime;
  });

  useDOMEvent("touchend", () => {
    draggingStateSet(null);
    if (playingWhenDraggingRef.current) {
      videoRef.current.play();
    }
    playingWhenDraggingRef.current = null;
  });

  // volume
  useEffect(() => {
    videoRef.current.volume = videoVolume / 100;
  }, [videoVolume]);

  // model
  const { isModalActive } = useContext(modalContext);
  const modelPlayingRef = useRef(false);
  useEffect(() => {
    if (!videoRef.current) return;

    if (!isModalActive) {
      modelPlayingRef.current = !videoRef.current.paused;
      videoRef.current.pause();
    } else {
      if (modelPlayingRef.current) videoRef.current.play();
    }
  }, [isModalActive]);

  // This function logs the default Google Analytics videos events as documented below:
  //  Reference: https://support.google.com/analytics/answer/9216061
  //
  // - video_start: when the video starts playing
  // - video_progress: when the video progresses past 10, 25, 50, and 75 percent of the duration time
  // - video_complete: when the video ends
  //
  // Default parameters for all events above:
  // Reference: https://support.google.com/analytics/table/13594742
  // - video_current_time (number): The current time in a video of the viewer. Example value: 1234.
  // - video_duration (number): The duration of a video. Example value: 1234.
  // - video_percent (number): The percentage of a video that a user watched. Example value: 45.
  // - video_title (text): The title of an embedded video.
  // - video_url (text): The url of an embedded video.
  // - visible (boolean): Set to 'true' if an embedded video is visible to the user, Example value: 1.
  const logVideoEvent = (event) => {
    if (!videoRef.current) return;

    gaEvent(event, {
      video_current_time: videoRef.current.currentTime,
      video_duration: videoRef.current.duration,
      video_percent: Math.floor((videoRef.current.currentTime / videoRef.current.duration) * 100),
      video_title: others.title,
      video_url: others.src,
      visible: 1,
    });
  };

  const [playing, playingSet] = useState(null);
  // Update video play progress to GA every 15 seconds
  useEffect(() => {
    if (playing) {
      logVideoEvent("video_start");
      const interval = setInterval(() => logVideoEvent("video_progress"), 15000);
      return () => clearInterval(interval);
    }
  }, [playing]);

  return (
    <HLSVideo
      {...others}
      style={{
        cursor: "pointer",
        userSelect: "none",
        ...others.style,
        outline: "none",
      }}
      onClick={(event) => {
        if (videoRef.current.paused) videoRef.current.play();
        else videoRef.current.pause();
        others.onClick?.(event);
      }}
      onTouchStart={(event) => {
        if (event.touches.length === 1) {
          playingWhenDraggingRef.current = !videoRef.current.paused;
          videoRef.current.pause();
          const rect = videoRef.current.getBoundingClientRect();
          const x = event.touches[0].clientX - rect.left;
          draggingStateSet({
            startX: x,
            startCurrentTime: videoRef.current.currentTime,
          });
        }
        others?.onTouchStart?.(event);
      }}
      onPlay={(event) => {
        playingSet(true);
        others.onPlay?.(event);
      }}
      onPause={(event) => {
        playingSet(false);
        others.onPause?.(event);
      }}
      onEnded={(event) => {
        playingSet(false);
        logVideoEvent("video_complete");
        others.onEnded?.(event);
      }}
      videoRef={mergeRefs(
        //
        others.videoRef,
        videoRef,
      )}
    />
  );
}
