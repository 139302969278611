import { gql } from "@apollo/client";
import { Alert, Skeleton } from "@mui/material";
import DateTime from "atoms/DateTime";
import { PageContentStack } from "atoms/Stack";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import useSearchTerm from "helpers/useSearchTerm";
import Subnav, {
  SubnavDropdown,
  SubnavDropdownItem,
  SubnavDropdownSearchField,
  SubnavToggleLabel,
} from "molecules/Subnav";
import React, { useLayoutEffect, useState } from "react";
import NotFoundPage from "shared/NotFoundPage";
import PageTitle from "shared/PageTitle";

import GamesSplash from "./games/GamesSplash";
import LeaderboardTableSegment, { LeaderboardTableSegmentFragment } from "./leaderboard/LeaderboardTableSegment";

export default function LeaderboardsPage() {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [leagueSearchTerm, leagueSearchTermSet, leagueSearchTermDebounced] = useSearchTerm();
  const [data] = useData(
    gql`
      query LeaderboardsPage($seasonId: ID, $leagueSearchTerm: String!) {
        season(id: $seasonId) {
          id
          nameWithLeague
          leaderboardTableSegments {
            title
            ...LeaderboardTableSegmentFragment
          }
        }
        currentUser {
          id
          gamesSeasons(searchTerm: $leagueSearchTerm, limit: 20) {
            id
            nameWithLeague
            firstPlayedAt
            lastPlayedAt
          }
        }
      }
      ${LeaderboardTableSegmentFragment}
    `,
    { seasonId, leagueSearchTerm: leagueSearchTermDebounced },
  );

  useLayoutEffect(() => {
    leagueSearchTermSet("");
  }, [seasonId]);

  const [displaySegmentTitle, displaySegmentTitleSet] = useState(null);

  if (data && !data.season) return <NotFoundPage />;

  return (
    <>
      <PageTitle title="Leaderboards" />
      <GamesSplash title="Leaderboards" subtitle={data?.season?.nameWithLeague} />
      <Subnav>
        <SubnavDropdown text={data?.season?.nameWithLeague}>
          <SubnavDropdownSearchField searchTerm={leagueSearchTerm} searchTermSet={leagueSearchTermSet} />
          {data?.currentUser?.gamesSeasons.map((season) => (
            <SubnavDropdownItem
              key={season.id}
              text={season.nameWithLeague}
              onClick={() => seasonIdSet(season.id)}
              selected={season.id === seasonId}
              description={
                <>
                  <DateTime value={season.firstPlayedAt} variant="month" /> -{" "}
                  <DateTime value={season.lastPlayedAt} variant="month" />
                </>
              }
            />
          ))}
        </SubnavDropdown>
        <SubnavToggleLabel
          checked={!displaySegmentTitle}
          onClick={() => displaySegmentTitleSet(null)}
          text="Overview"
        />
        {data?.season?.leaderboardTableSegments.map((leaderboardTableSegment) => (
          <SubnavToggleLabel
            key={leaderboardTableSegment.title}
            checked={displaySegmentTitle === leaderboardTableSegment.title}
            onClick={() => displaySegmentTitleSet(leaderboardTableSegment.title)}
            text={leaderboardTableSegment.title}
          />
        ))}
      </Subnav>
      <PageContentStack>
        {!data && <Skeleton variant="rectangular" height={200} />}
        {data && !data.season?.leaderboardTableSegments.length && (
          <Alert variant="outlined" severity="info" icon={false}>
            <p>Leaderboards are not yet available. Change filter to view other leaderboards.</p>
          </Alert>
        )}
        {data?.season?.leaderboardTableSegments
          .filter((s) => !displaySegmentTitle || s.title === displaySegmentTitle)
          .map((leaderboardTableSegment) => (
            <LeaderboardTableSegment
              key={leaderboardTableSegment.title}
              leaderboardTableSegment={leaderboardTableSegment}
              collapsible={!displaySegmentTitle}
            />
          ))}
      </PageContentStack>
    </>
  );
}
