import { gql } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TextWithTooltip from "atoms/TextWithTooltip";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import { SPACING, SPACING_DENSE } from "helpers/spacings";
import { defer } from "lodash-es";
import React, { createContext, useContext } from "react";

const statsTableContext = createContext();

export default function StatsTable({ headerColumns, color = COLOR_WHITE, inverse = false, columns, children }) {
  return (
    <statsTableContext.Provider value={{ inverse }}>
      <Paper
        style={{
          overflowX: "auto",
          ...(inverse && {
            backgroundColor: "transparent",
          }),
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {headerColumns?.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  style={{
                    textTransform: "uppercase",
                    color: COLOR_WHITE,
                    backgroundColor: COLOR_BLACK,
                    ...(inverse && {
                      backgroundColor: "transparent",
                    }),
                    fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                  }}
                >
                  {column}
                </TableCell>
              ))}
              {columns?.map((column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  style={{
                    textTransform: "uppercase",
                    textAlign: "center",
                    color: COLOR_WHITE,
                    ...(columnIndex === 0 && {
                      color,
                    }),
                    backgroundColor: COLOR_BLACK,
                    ...(inverse && {
                      backgroundColor: "transparent",
                    }),
                    fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </Paper>
    </statsTableContext.Provider>
  );
}

export function StatsTableRow({ children, highlighted = false }) {
  const { inverse } = useContext(statsTableContext);
  return (
    <TableRow
      selected={highlighted}
      style={{
        ...(inverse && {
          color: COLOR_WHITE,
        }),
      }}
    >
      {children}
    </TableRow>
  );
}

export function StatsTableButtonRow({ children }) {
  return (
    <TableRow>
      <TableCell colSpan={99} padding="none">
        {children}
      </TableCell>
    </TableRow>
  );
}

export function StatsTableHeaderCell({ children, color, large = false, tooltip }) {
  const { inverse } = useContext(statsTableContext);
  color = color || (inverse ? COLOR_WHITE : null);
  return (
    <TableCell padding="none">
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            fontSize: 12,
            fontFamily: FONT_FAMILY_ROBOTO,
            padding: SPACING_DENSE,
            ...(large && {
              padding: SPACING,
              fontSize: 18,
              fontWeight: "bold",
            }),
            color,
          }}
        >
          {children}
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}

export const StatsTableStatCellFragment = gql`
  fragment StatsTableStatCellFragment on Stat {
    statType
    value
    made
    total
  }
`;

export function StatsTableStatCell({ stat, ...others }) {
  if (stat?.statType === "simple") return <StatsTableCell value={String(stat.value)} {...others} />;
  if (stat?.statType === "percentage")
    return <StatsTablePercentageCell made={stat.made} total={stat.total} {...others} />;
  if (stat)
    defer(() => {
      throw new Error(`Unknown stat type: ${stat.statType}`);
    });
  return <StatsTableCell value="?" />;
}

export function StatsTableCell({ value, large = false, description, tooltip }) {
  const { inverse } = useContext(statsTableContext);

  return (
    <TableCell padding="none">
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            ...(large && {
              padding: SPACING,
            }),
            ...(inverse && {
              color: COLOR_WHITE,
            }),
          }}
        >
          <div
            style={{
              fontSize: 18,
              lineHeight: 1,
              textAlign: "center",
              fontFamily: FONT_FAMILY_ROBOTO,
            }}
          >
            {value}
          </div>
          <div style={{ fontSize: 10 }}>{description}</div>
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}

export function StatsTablePercentageCell({ made, total, tooltip }) {
  const percentage = total > 0 ? Math.round((made * 100) / total) : 0;
  const { inverse } = useContext(statsTableContext);

  return (
    <TableCell
      padding="none"
      style={{
        textAlign: "center",
        ...(inverse && {
          color: COLOR_WHITE,
        }),
      }}
    >
      <TextWithTooltip tooltip={tooltip}>
        <div
          style={{
            fontSize: 16,
            fontFamily: FONT_FAMILY_ROBOTO,
          }}
        >
          {made} - {total}
        </div>
        <div
          style={{
            fontSize: 12,
          }}
        >
          {percentage}%
        </div>
      </TextWithTooltip>
    </TableCell>
  );
}
