import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import mergeRefs from "helpers/mergeRefs";
import { SPACING, SPACING_DENSE, SPACING_PADDING, SPACING_VERY_DENSE } from "helpers/spacings";
import React, { cloneElement, createContext, forwardRef } from "react";

export const stackContext = createContext({});

const Stack = forwardRef(function Stack(
  {
    component: Component = "div",
    element = <Component />,
    componentRef,
    children,
    inverse = false,
    horizontal = false,
    // warning: we should avoid using nowrap for as much as possible for responsive design
    noWrap = false,
    dense = false,
    veryDense = false,
    noGap = false,
    alignItemsCenter = false,
    alignItemsStart = false,
    alignItemsEnd = false,
    padding = false,
    reverse = false,
    ...others
  },
  ref,
) {
  let spacing = SPACING;
  if (dense) {
    spacing = SPACING_DENSE;
  } else if (veryDense) {
    spacing = SPACING_VERY_DENSE;
  } else if (noGap) {
    spacing = 0;
  }

  return (
    <stackContext.Provider value={{ dense, veryDense }}>
      {cloneElement(element, {
        ref: mergeRefs(ref, componentRef),
        ...others,
        style: {
          ...element.props.style,
          display: "flex",
          gap: spacing,
          flexDirection: reverse ? "column-reverse" : "column",
          ...(horizontal && {
            flexDirection: reverse ? "row-reverse" : "row",
            // wrapping should only be used for horizontal stacks
            flexWrap: noWrap ? "nowrap" : "wrap",
          }),
          justifyContent: "flex-start",
          alignItems: "stretch",
          ...(alignItemsCenter && {
            alignItems: "center",
          }),
          ...(alignItemsStart && {
            alignItems: "flex-start",
          }),
          ...(alignItemsEnd && {
            alignItems: "flex-end",
          }),
          ...(padding && {
            padding: spacing,
          }),
          ...(inverse && {
            color: COLOR_WHITE,
            backgroundColor: COLOR_BLACK,
          }),
          ...others.style,
        },
        children,
      })}
    </stackContext.Provider>
  );
});

export default Stack;

export function PageContentStack({ children, ...others }) {
  return (
    <Stack
      {...others}
      style={{
        padding: SPACING_PADDING,
        ...others.style,
      }}
    >
      {children}
    </Stack>
  );
}
