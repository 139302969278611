import { alpha, ButtonBase, CircularProgress } from "@mui/material";
import Spacer from "atoms/Spacer";
import Stack from "atoms/Stack";
import VideoPlayer from "atoms/VideoPlayer";
import { COLOR_BLACK, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { ONSEN_APP } from "helpers/environment";
import { fetchFile } from "helpers/fetchFile";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING } from "helpers/spacings";
import useFullscreenState from "helpers/useFullscreenState";
import { progressBar } from "helpers/useProgressBar";
import useShowMessage from "helpers/useShowMessage";
import { ContentCopy, Download, Link, Play, Share } from "mdi-material-ui";
import ViewCountLabel from "molecules/ViewCountLabel";
import React, { cloneElement, useLayoutEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use-size";
import { useAsyncMemo } from "use-async-memo";

import FullscreenButton from "./FullscreenButton";
import PlayerButton from "./PlayerButton";
import VolumeButton from "./VolumeButton";

export default function ReelVideoPlayer({
  title,
  avatar,
  subtitle,
  poster,
  videoUrl,
  shareUrl,
  viewCount,
  videoPending = false,
  vidoeUnavailable = false,
  onDownload,
  onVideoPlay,
  onShared,
  sharingDisabled = false,
}) {
  const [fullscreen] = useFullscreenState();
  const [audioEnabled, audioEnabledSet] = useState(true);
  const [buffering, bufferingSet] = useState(false);
  const [playing, playingSet] = useState(false);
  const [playingProgress, playingProgressSet] = useState(0);
  const videoRef = useRef();
  const playedRef = useRef(false);

  const fetchFileResult = useAsyncMemo(
    //
    () => title && videoUrl && fetchFile(videoUrl, title),
    [title, videoUrl],
  );

  useLayoutEffect(() => {
    bufferingSet(true);
  }, [videoUrl]);

  const { file, filename, fileObjectUrl } = fetchFileResult || {};

  const showMessage = useShowMessage();
  const windowSize = useWindowSize();
  const smallScreen = windowSize.height < 400;
  const restrictSize = !smallScreen && !fullscreen;

  return (
    <div
      style={{
        flex: "1 1 auto",
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        display: "flex",
        justifyContent: "stretch",
        alignItems: "stretch",
        ...(restrictSize && {
          justifyContent: "center",
          alignItems: "center",
        }),
      }}
    >
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
          alignItems: "stretch",
          gap: SPACING,
          ...(restrictSize && {
            maxWidth: 1000,
          }),
          position: "relative",
        }}
      >
        <Stack
          horizontal
          dense
          alignItemsCenter
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
            backgroundColor: alpha(COLOR_BLACK, 0.5),
          }}
          padding
        >
          {avatar && cloneElement(avatar, { size: 42, inverse: true })}
          <Stack dense>
            <div
              style={{
                fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                fontSize: "1.5em",
              }}
            >
              {title}
            </div>
            <div>{subtitle}</div>
          </Stack>
        </Stack>
        <ButtonBase
          component="div"
          style={{
            flex: "1 1 0",
            position: "relative",
            ...(restrictSize && {
              aspectRatio: "16 / 9",
              flex: "0 0 auto",
            }),
          }}
          disabled={!videoUrl}
        >
          {!videoUrl && (
            <Stack dense alignItemsCenter>
              {!vidoeUnavailable && <CircularProgress color="inherit" />}
              {vidoeUnavailable && <div>Video unavailable</div>}
              {videoPending && <div>Processing video...</div>}
            </Stack>
          )}
          <VideoPlayer
            loop
            autoPlay
            playsInline
            preload="auto"
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "top center",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            poster={poster}
            src={videoUrl}
            onPlaying={() => playingSet(true)}
            onPause={() => playingSet(false)}
            onWaiting={() => bufferingSet(true)}
            onCanPlay={() => bufferingSet(false)}
            onTimeUpdateUnthrottled={() => playingProgressSet(videoRef.current.currentTime / videoRef.current.duration)}
            videoRef={videoRef}
            onAudioEnabledChange={audioEnabledSet}
            onCanPlayThrough={() => {
              if (playedRef.current) return;
              playedRef.current = true;
              onVideoPlay?.();
            }}
          />
          {videoUrl && (
            <>
              <div
                style={{
                  pointerEvents: "none",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  transition: "opacity ease .3s",
                  opacity: buffering || !playing ? 1 : 0,
                }}
              >
                {buffering ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <Play
                    fontSize="inherit"
                    style={{
                      color: COLOR_WHITE,
                      fontSize: 64,
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 4,
                  width: `${playingProgress * 100}%`,
                  backgroundColor: COLOR_SECONDARY,
                }}
              />
            </>
          )}
        </ButtonBase>
        <Stack
          horizontal
          dense
          alignItemsCenter
          padding
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
            backgroundColor: alpha(COLOR_BLACK, 0.5),
          }}
        >
          <VolumeButton enabled={audioEnabled} videoRef={videoRef} />
          <Spacer />
          <ViewCountLabel viewCount={viewCount} inverse />
          {!sharingDisabled && (
            <>
              {!ONSEN_APP && (
                <PlayerButton
                  title="Download"
                  onClick={() => {
                    const a = document.createElement("a");
                    a.download = filename;
                    a.href = fileObjectUrl;
                    a.click();
                    onShared?.({ share_destination: "download" });
                    onDownload?.();
                  }}
                  disabled={!file}
                  icon={<Download />}
                />
              )}
              <PlayerButton title="Open in new tab" target="_blank" href={shareUrl} icon={<Link />} />
              <PlayerButton
                title="Copy link"
                onClick={() =>
                  progressBar(async () => {
                    await window.navigator.clipboard.writeText(shareUrl);
                    await showMessage("Link copied to clipboard.");
                    onShared?.({ share_destination: "copy_link" });
                  })
                }
                icon={<ContentCopy />}
              />
              {!!window?.navigator?.share && (
                <PlayerButton
                  title="Share"
                  disabled={!file}
                  onClick={() =>
                    progressBar(async () => {
                      window.navigator.share({
                        files: [file],
                      });
                      onShared?.({ share_destination: "web_share" });
                    })
                  }
                  icon={<Share />}
                />
              )}
            </>
          )}
          <FullscreenButton video={videoRef.current} />
        </Stack>
      </div>
    </div>
  );
}
