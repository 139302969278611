import { gql } from "@apollo/client";
import { alpha, ButtonBase } from "@mui/material";
import Stack from "atoms/Stack";
import { COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import formatTimeString from "helpers/formatTime";
import { gaEvent } from "helpers/gtag";
import useData from "helpers/useData";
import { Star } from "mdi-material-ui";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import EventSubjectLabel, { EventSubjectLabelFragment } from "molecules/EventSubjectLabel";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext, useEffect, useMemo } from "react";

import EventActions, { EventActionsFragment } from "./EventActions";
import { videoPageLayoutContext } from "./VideoPageLayout";

export const VideoEventsFragment = gql`
  fragment VideoEventsFragment on Game {
    id
    ...EventActionsFragment
    events {
      id
      startAtSeconds
      occurredAtSeconds
      ...EventSubjectLabelFragment
      eventView {
        id
        humanName
        wrapper
        ...EventAvatarFragment
      }
    }
  }
  ${EventSubjectLabelFragment}
  ${EventActionsFragment}
  ${EventAvatarFragment}
`;

export default function VideoEvents({ game }) {
  const { activeEventId, filteredEventIds, videoRef } = useContext(videoPageContext);
  const { sidebarOpen } = useContext(videoPageLayoutContext);
  const eventElements = useMemo(() => ({}), []);
  useEffect(() => {
    const activeEventElement = eventElements[activeEventId];
    if (activeEventElement && sidebarOpen) {
      activeEventElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [activeEventId]);

  return (
    <>
      {game?.events
        .filter((e) => filteredEventIds.includes(e.id))
        .map((event) => (
          <ButtonBase
            component="div"
            tabIndex={-1}
            key={event.id}
            ref={(el) => {
              eventElements[event.id] = el;
            }}
            style={{
              display: "flex",
              flexFlow: "row wrap",
              textAlign: "start",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: 12,
              borderBottom: `1px solid ${alpha(COLOR_WHITE, 0.1)}`,
              ...(activeEventId === event.id && {
                backgroundColor: alpha(COLOR_WHITE, 0.1),
              }),
            }}
          >
            <Stack
              dense
              padding
              horizontal
              alignItemsCenter
              style={{ flex: "1 0 200px" }}
              element={
                <ButtonBase
                  disableRipple
                  style={{ textAlign: "start" }}
                  onClick={() => {
                    if (videoRef.current) {
                      videoRef.current.currentTime = event.startAtSeconds;
                    }
                    gaEvent("game_highlight_select", {
                      event_type: event.eventView.wrapper,
                    });
                  }}
                />
              }
            >
              <div>{formatTimeString(event.occurredAtSeconds)}</div>
              <EventAvatar inverse eventView={event.eventView} size={32} active={activeEventId === event.id} />
              <Stack
                veryDense
                style={
                  {
                    // big enough to push actions button to another line
                  }
                }
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  <EventSubjectLabel event={event} />
                </div>
                <Stack
                  veryDense
                  horizontal
                  alignItemsCenter
                  style={{
                    opacity: 0.8,
                  }}
                >
                  <div>{event.eventView.humanName}</div>
                  <EventPotwStar eventId={event.id} />
                </Stack>
              </Stack>
            </Stack>
            <Stack padding veryDense horizontal alignItemsCenter>
              {activeEventId === event.id && <EventActions game={game} eventId={event.id} />}
            </Stack>
          </ButtonBase>
        ))}
    </>
  );
}

function EventPotwStar({ eventId }) {
  const [data] = useData(
    gql`
      query EventPotwStar($eventId: ID!) {
        event(id: $eventId) {
          id
          potwEntriesAny
        }
      }
    `,
    { eventId },
  );

  return data?.event?.potwEntriesAny && <Star fontSize="inherit" style={{ color: COLOR_SECONDARY }} />;
}
