import { gql } from "@apollo/client";
import { alpha, ButtonBase, Popover } from "@mui/material";
import ClipSvg from "assets/reactions/clip.svg";
import Avatar from "atoms/Avatar";
import HoverModal from "atoms/HoverModal";
import Stack from "atoms/Stack";
import StackedAvatars from "atoms/StackedAvatars";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_GREY, COLOR_PRIMARY_DARKER, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { EVENT_DEFAULT_FINISH_OFFSET_SECONDS, EVENT_DEFAULT_START_OFFSET_SECONDS } from "helpers/constants";
import { getEventTemplate } from "helpers/EVENT_TEMPLATES";
import { gaEvent } from "helpers/gtag";
import REACTION_TYPES from "helpers/REACTION_TYPES";
import {
  SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_REACTION,
  SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_STAT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_REACTION,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_STAT,
} from "helpers/SHORTCUTS";
import useActionFragment from "helpers/useActionFragment";
import useDOMEvent from "helpers/useDOMEvent";
import { Plus } from "mdi-material-ui";
import FormDialog from "molecules/FormDialog";
import { videoPageContext } from "pages/VideoPage";
import ModalContextProvider from "providers/ModalContextProvider";
import React, { useContext, useState } from "react";

import HighlightEditor, { HighlightEditorFragment } from "./HighlightEditor";

export const VideoNewHighlightButtonsFragment = gql`
  fragment VideoNewHighlightButtonsFragment on Game {
    id
    canOnsenTagVideo
    league {
      id
      sport
    }
    durationSeconds
    home {
      id
    }
    currentUserDefaultGamePlayer {
      id
      team {
        id
      }
      person {
        id
      }
    }
    ...HighlightEditorFragment
  }
  ${HighlightEditorFragment}
`;

export default function VideoNewHighlightButtons({ game }) {
  const onsenUpsertEvent = useActionFragment("onsenUpsertEvent", "game { id events { id } }");
  const [newReactionOpen, newReactionOpenSet] = useState(false);
  const [newHighlightOpen, newHighlightOpenSet] = useState(false);
  const { floatingControlsRef, currentPeriodNumber, currentTime, isVideoPageModalActive } =
    useContext(videoPageContext);

  // new highlight
  const [event, eventSet] = useState({});

  const eventTemplate = getEventTemplate(game?.league.sport, event);
  const eventValid = !!eventTemplate;

  const openNewHighlight = () => {
    eventSet({
      occurredAtSeconds: currentTime,
      startAtSeconds: Math.max(currentTime - EVENT_DEFAULT_START_OFFSET_SECONDS, 0),
      finishAtSeconds: Math.min(currentTime + EVENT_DEFAULT_FINISH_OFFSET_SECONDS, game.durationSeconds),
      team: game.currentUserDefaultGamePlayer?.team || game.home,
      person: game.currentUserDefaultGamePlayer?.person,
      eventView: {},
    });
    newHighlightOpenSet(true);
    gaEvent("event_create_open");
  };

  useDOMEvent("keydown", (event) => {
    if (!isVideoPageModalActive) return;
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_STAT) openNewHighlight();
    if (event.code === SHORTCUT_KEY_CODE_VIDEO_PAGE_NEW_REACTION) newReactionOpenSet(true);
  });

  if (!game?.canOnsenTagVideo) {
    return null;
  }

  return (
    <>
      <HoverModal
        element={
          <StackedAvatars
            size={48}
            primaryAvatar={
              <Avatar
                element={
                  <WithTooltip
                    tooltip="Add stat"
                    shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_STAT}
                    inverse
                    placement="top"
                    element={
                      <ButtonBase
                        focusRipple
                        accessKey="d"
                        disabled={!game}
                        data-video-joyride="tag-stat-highlights"
                        onClick={() => {
                          openNewHighlight();
                        }}
                      />
                    }
                  />
                }
                color={COLOR_SECONDARY}
                text="STAT"
              />
            }
            secondaryAvatar={<Avatar color={alpha(COLOR_GREY, 0.8)} text={<Plus fontSize="inherit" />} />}
          />
        }
      />
      <HoverModal
        element={
          <StackedAvatars
            size={48}
            primaryAvatar={
              <Avatar
                element={
                  <WithTooltip
                    tooltip="Add reaction"
                    shortcutLabel={SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_REACTION}
                    accessKey="r"
                    inverse
                    placement="top"
                    element={
                      <ButtonBase
                        data-video-joyride="tag-reaction-highlights"
                        focusRipple
                        onClick={() => newReactionOpenSet(true)}
                      />
                    }
                  />
                }
                imgSrc={ClipSvg}
              />
            }
            secondaryAvatar={<Avatar color={alpha(COLOR_GREY, 0.8)} text={<Plus fontSize="inherit" />} />}
          />
        }
      />
      <ModalContextProvider open={newReactionOpen} name="new-reaction">
        <Popover
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={floatingControlsRef.current}
          open={newReactionOpen}
          onClose={() => newReactionOpenSet(false)}
          slotProps={{
            paper: {
              style: { backgroundColor: COLOR_PRIMARY_DARKER, color: COLOR_WHITE },
            },
          }}
        >
          <Stack horizontal dense padding>
            {REACTION_TYPES.map((reactionType) => (
              <Avatar
                key={reactionType.name}
                size={48}
                imgSrc={reactionType.imgSrc}
                element={
                  <WithTooltip
                    tooltip={reactionType.title}
                    inverse
                    placement="top"
                    element={
                      <ButtonBase
                        focusRipple
                        disabled={!game}
                        onClick={async () => {
                          await onsenUpsertEvent({
                            input: {
                              gameId: game.id,
                              wrapper: "Reaction",
                              occurredAtSeconds: currentTime,
                              period: currentPeriodNumber,
                              reactionType: reactionType.name,
                            },
                          });
                          newReactionOpenSet(false);
                          gaEvent("event_create_reaction", {
                            reaction_type: reactionType.name,
                          });
                        }}
                      />
                    }
                  />
                }
              />
            ))}
          </Stack>
        </Popover>
      </ModalContextProvider>
      <FormDialog
        inverse
        title="Add Highlight"
        open={newHighlightOpen}
        onClose={() => newHighlightOpenSet(false)}
        onSubmit={
          eventValid &&
          (async () => {
            await onsenUpsertEvent({
              input: {
                gameId: game.id,
                period: currentPeriodNumber,
                personId: event.person?.id,
                teamId: event.team?.id,
                occurredAtSeconds: event.occurredAtSeconds,
                startAtSeconds: event.startAtSeconds,
                finishAtSeconds: event.finishAtSeconds,
                ...eventTemplate.params,
              },
            });
            gaEvent("event_create_save", {
              event_type: eventTemplate.params.wrapper,
            });
          })
        }
        fullWidth
        fullHeight
        submitButtonLabel={`Add ${eventTemplate?.title}`}
      >
        <HighlightEditor open={newHighlightOpen} game={game} event={event} eventSet={eventSet} />
      </FormDialog>
    </>
  );
}
