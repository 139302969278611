import { gql } from "@apollo/client";
import { COLOR_ASSIST, COLOR_REBOUND } from "helpers/colors";
import EVENT_TEMPLATES, { EVENT_DEFAULT_PARAMS, EVENT_PARAMS_KEYS, EventParamsFragment } from "helpers/EVENT_TEMPLATES";
import { gaEvent } from "helpers/gtag";
import useActionFragment from "helpers/useActionFragment";
import { isEqual, pick } from "lodash-es";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

import { EventPrompt, EventPromptFragment } from "./EventPrompt";

export const HighlightHubPromptsFragment = gql`
  fragment HighlightHubPromptsFragment on Game {
    events {
      id
      occurredAtSeconds
      pointsNoAssist
      noRebound
      eventView {
        id
        ...EventParamsFragment
        wrapper
      }
    }
    ...EventPromptFragment
  }
  ${EventPromptFragment}
  ${EventParamsFragment}
`;

const ASSIST_TIMELINE_OFFSET_SECONDS = -0.5;
const REBOUND_TIMELINE_OFFSET_SECONDS = 0.5;

export default function HighlightHubPrompts({ game }) {
  const { activeEventId, gameId, currentPeriodNumber } = useContext(videoPageContext);
  const activeEvent = game?.events.find((event) => event.id === activeEventId);
  const activeEventTemplace =
    activeEvent &&
    EVENT_TEMPLATES.find((template) =>
      isEqual(
        {
          ...EVENT_DEFAULT_PARAMS,
          ...template.params,
        },
        pick(activeEvent.eventView, EVENT_PARAMS_KEYS),
      ),
    );

  const onsenUpsertEvent = useActionFragment(
    //
    "onsenUpsertEvent",
    "event { id pointsNoAssist noRebound }",
    "game { id events { id } }",
  );

  return (
    <>
      <EventPrompt
        data-test-assist-prompt
        game={game}
        enabled={activeEventTemplace?.assistPromptEnabled && !activeEvent?.pointsNoAssist}
        color={COLOR_ASSIST}
        title="Assist?"
        noEventLabel="No Assist"
        enableSameTeamGamePlayers
        onSelectNoEvent={async () => {
          await onsenUpsertEvent({
            input: {
              gameId,
              eventId: activeEventId,
              pointsNoAssist: true,
            },
          });
          gaEvent("assist_prompt_no_assist", { event_type: activeEvent.eventView.wrapper });
        }}
        onSelectGamePlayer={async ({ personId, teamId }) => {
          await onsenUpsertEvent({
            input: {
              gameId,
              personId,
              teamId,
              wrapper: activeEventTemplace.assistPromptWrapper,
              occurredAtSeconds: activeEvent.occurredAtSeconds + ASSIST_TIMELINE_OFFSET_SECONDS,
              period: currentPeriodNumber,
            },
          });
          await onsenUpsertEvent({
            input: {
              gameId,
              eventId: activeEventId,
              pointsNoAssist: true,
            },
          });
          gaEvent("assist_prompt_create_event", { event_type: activeEvent.eventView.wrapper });
        }}
      />
      <EventPrompt
        data-test-rebound-prompt
        game={game}
        enabled={activeEventTemplace?.reboundPromptEnabled && !activeEvent?.noRebound}
        color={COLOR_REBOUND}
        title="Rebound?"
        noEventLabel="No Rebound"
        enableSameTeamGamePlayers
        enableOtherTeamGamePlayers
        onSelectNoEvent={async () => {
          await onsenUpsertEvent({
            input: {
              gameId,
              eventId: activeEventId,
              noRebound: true,
            },
          });
          gaEvent("rebound_prompt_no_rebound", { event_type: activeEvent.eventView.wrapper });
        }}
        onSelectGamePlayer={async ({ personId, teamId }) => {
          await onsenUpsertEvent({
            input: {
              gameId,
              personId,
              teamId,
              wrapper: activeEventTemplace.reboundPromptWrapper,
              reboundType: teamId === activeEvent.team.id ? "offensive" : "defensive",
              occurredAtSeconds: activeEvent.occurredAtSeconds + REBOUND_TIMELINE_OFFSET_SECONDS,
              period: currentPeriodNumber,
            },
          });
          await onsenUpsertEvent({
            input: {
              gameId,
              eventId: activeEventId,
              noRebound: true,
            },
          });
          gaEvent("rebound_prompt_create_event", { event_type: activeEvent.eventView.wrapper });
        }}
      />
    </>
  );
}
